import React, { useEffect, useContext, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { ReactComponent as NotLatLon } from "assets/svg/not_lat_lon.svg";
import useStyles from "../styles";
import terminalService from "services/terminal.service";
import {
  Alert,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  SnackbarContent,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ReactJson from "react-json-view";

interface Response {
  data: any;
  message: string;
  status: number;
}
interface BusinessConfig {
  application_code: string;
  business_config_id: number;
  business_description: string;
  business_property: string;
  business_value: any;
}

const ParamCell = React.memo(
  ({ title, data }: { title: string; data: any }) => {
    const [open, setOpen] = useState(false);

    const isJson = (item: any) => {
      if (typeof item !== "string") {
        try {
          JSON.parse(JSON.stringify(item));
          return true;
        } catch {
          return false;
        }
      }
      return false;
    };

    return (
      <>
        <TableRow onClick={() => setOpen(!open)}>
          <TableCell>{title}</TableCell>
          <TableCell sx={{ justifyContent: "flex-end", display: "flex" }}>
            <IconButton
              aria-label="Abrir"
              aria-expanded={open}
              size="small"
              onClick={() => setOpen(!!!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ maxWidth: "90vw" }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableCell sx={{ width: "90vw" }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{ maxWidth: "90vw" }}
                  size="small"
                  aria-label="Properties"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Property</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data && data.length > 0 ? (
                      (data as Array<BusinessConfig>).map((row) => (
                        <TableRow
                          key={row.business_config_id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            {row.business_property}
                          </TableCell>
                          <TableCell align="left">
                            {row.business_description}
                          </TableCell>
                          <TableCell align="left">
                            {isJson(row.business_value) ? (
                              <>
                                <ReactJson
                                  src={row.business_value}
                                  name={null}
                                  enableClipboard={false}
                                  groupArraysAfterLength={5}
                                  collapseStringsAfterLength={25}
                                  collapsed={true}
                                  displayDataTypes={false}
                                />
                              </>
                            ) : (
                              row.business_value
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          <Alert severity="error">
                            Ocurrio un error inesperado, intentelo nuevamente.
                          </Alert>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableCell>
          </Collapse>
        </TableRow>
      </>
    );
  }
);
ParamCell.displayName = "ParamCell";

const Parameters = (props: any) => {
  const styles = useStyles();

  const [businessConfig, setBusinessConfig] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const callServiceGetConfig = async () => {
    try {
      const { data: resp }: { data: Response } =
        await terminalService.business_config_get();
  
      if (resp.status === 200) {
        setBusinessConfig(resp?.data);
      }
    } catch (error) {
      console.error("Error fetching business config:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    callServiceGetConfig();
  }, []);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card>
            <TableContainer>
              {businessConfig ? (
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ padding: "1rem" }}
                >
                  <TableHead></TableHead>
                  <TableBody sx={{ maxWidth: "90vw" }}>
                    {Object.entries(businessConfig).map(([key, value]) => (
                      <ParamCell key={key} title={key} data={value} />
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Grid>
                  <div
                    style={{
                      background: "rgba(255, 255, 255, 0.8)",
                      top: 0,
                      borderTopRightRadius: "8px",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80vh",
                      width: "100%",
                    }}
                  >
                    <Card className={styles.notLatLon}>
                      {isLoading ? <CircularProgress /> : <NotLatLon />}
                    </Card>
                  </div>
                </Grid>
              )}
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Parameters;
