// Layout
import LayoutHome from "layouts/LayoutHome";

//Login
//import Login from "pages/login";

//Home
import Home from "pages/home";
import TerminalDashboard from "pages/home/terminalDashboard";
//Home
import AdministrationPanel from "pages/adminPanel/AdminPanelTabs";
import Reports from "pages/auditLog/";
import ErrorsHistory from "pages/errors-history";

//Error404
import Error404 from "pages/error404";

const routes = [
  {
    path: "/",
    component: LayoutHome,
    exact: false,
    routes: [
      {
        path: "/",
        component: Home,
        exact: true,
      },
      {
        path: "/home",
        component: Home,
        exact: true,
      },
      {
        path: "/home/terminal-dashboard/:serial/:merchant/:id_terminal",
        component: TerminalDashboard,
        exact: true,
      },
      {
        //TODO HERE
        path: "/admin-panel",
        component: AdministrationPanel,
        exact: true,
      },
      {
        path: "/reports",
        component: Reports,
        exact: true,
      },
      {
        path: "/errors-history",
        component: ErrorsHistory,
        exact: true
      },
      {
        component: Error404,
      },
    ],
  },
];

export default routes;
