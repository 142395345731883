import React, { useState, useEffect, useContext, useCallback } from "react";
import colors from "constants/colors";
//import { v4 as uuidv4 } from "uuid";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Skeleton from "@mui/material/Skeleton";
import ErrorBoundary from "components/errorBoundary";
import AlertMessage from "components/alertMessage";
import { ReactComponent as FilterIcon } from "assets/svg/filter.svg";
import { ReactComponent as LoadingIcon } from "assets/svg/buttonIcon/loading.svg";
import useStyles from "./styles";
import RoleContext from "context/userRole/RoleContext";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory, Prompt } from "react-router-dom";
import TableCustom from "./table";
import TerminalService from "../../services/terminal.service";
import OpenDrawerContext from "context/pages/OpenDrawerContext";
import { useWindowSize } from "hooks/useWindowSize";
import "./hideArrows.css";
import mixpanel from "mixpanel-browser";

type Props = any;

const style = {
  bgcolor: "background.paper",
  border: `1px solid #E9EDF5`,
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ErrorsHistory: React.FC<Props> = () => {
  const windowSize = useWindowSize();

  const { drawerOpen, setUpdateDrawer } = useContext(OpenDrawerContext);

  const handleDrawerOpen = React.useCallback(() => {
    setUpdateDrawer(true);
  }, []);

  const handleDrawerClose = React.useCallback(() => {
    setUpdateDrawer(false);
  }, []);

  //open Drawer in width 1366
  useEffect(() => {
    if (windowSize.width >= 1366) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
  }, [windowSize.width]);

  const styles = useStyles();

  const monthBefore = new Date();
  monthBefore.setDate(monthBefore.getDate() - 30);
  monthBefore.setHours(0, 0, 0, 0);

  const [localErrors, setLocalErrors] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  
  const [fetchPage, setFetchPage] = useState<number>(1);
  const [initDate, setInitDate] = useState<Date>(monthBefore);
  const [finishDate, setFinishDate] = useState<Date>(new Date());
  const [queryBin, setQueryBin] = useState<string>("");
  const [queryTerminalId, setQueryTerminalId] = useState<string>("");
  const [queryCode, setQueryCode] = useState<string>("");
  const [queryMessage, setQueryMessage] = useState<string>("");
  const [filtered, setFiltered] = useState<boolean>(false);

  const dayBefore = new Date(finishDate);
  dayBefore.setDate(dayBefore.getDate() - 1);
  dayBefore.setHours(23, 59, 59, 0);

  const dayAfter = new Date(initDate);
  dayAfter.setDate(dayAfter.getDate() + 1);
  dayAfter.setHours(0, 0, 0, 0);

  const fetchErrorsHistory = async (
    currentPage: number,
    dateFrom: Date,
    dateTo: Date,
    terminalId: string | null,
    errorCode: string | null,
    errorMessage: string | null,
    bin: string | null
  ) => {
    try {
      setLoading(true);
      dateTo.setHours(23, 59, 59, 0);
      const res = await TerminalService.local_errors({
        current_page: currentPage,
        page_size: 100,
        date_from: dateFrom,
        date_to: dateTo,
        terminalId: terminalId === "" ? null : terminalId,
        error_code: errorCode === "" ? null : errorCode,
        error_message: errorMessage === "" ? null : errorMessage,
        bin: bin === "" ? null : bin
      });
      if (res.status === 200) {
        setLoading(false);
        setLocalErrors(res.data);
        mixpanel.track("errors_history_screen", {
          Screen: "Errors History - Filter",
          Filter: {
            from: dateFrom.toISOString(),
            to: dateTo.toISOString(),
            errorCode: errorCode || "",
            errorMessage: errorMessage || "",
            bin: bin || "",
            page: fetchPage
          }
        });
      }
    } catch (error: any) {
      setLoading(false);
      setLocalErrors(null);
      console.log(error.message);
    }
  };

  const history = useHistory();
  const { role } = useContext(RoleContext);

  const { keycloak } = useKeycloak();
  
  useEffect(() => {
    setLoading(true);
    fetchErrorsHistory(fetchPage, initDate, finishDate,  queryTerminalId, queryCode, queryMessage, queryBin);
  }, [fetchPage]);

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const validateEmpty = queryTerminalId == "" && queryCode == "" && queryMessage == "" && queryBin == "" ? true : false;

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setFinishDate(new Date());
    setInitDate(monthBefore);
    setQueryTerminalId("");
    setQueryCode("");
    setQueryMessage("");
    setQueryBin("");
  };

  return (
    <Grid
      className={styles.root}
      style={{
        paddingLeft: drawerOpen
          ? windowSize.width < 1200
            ? "5rem"
            : "17rem"
          : "5rem",
      }}
    >
      <Card
        elevation={0}
        sx={{
          ...style,
          flexGrow: 1,
          boxShadow: "none"
        }}
      >
        <Typography
          style={{
            fontSize: "30px",
            paddingTop: "0.5em",
            color: colors.primary,
          }}
        >
          Historial de Errores
        </Typography>
        <Grid container spacing={2} paddingTop=".8rem">
          <Grid item xs={12} xl={12}>
            <Card className={styles.cardInternal}>
              <Grid className={styles.gridInternal}>
                <ErrorBoundary>
                  <Grid
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <Grid style={{ paddingRight: "1rem" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={
                            <span style={{ color: colors.grayText }}>
                              Fecha desde
                            </span>
                          }
                          value={initDate}
                          inputFormat={"dd/MM/yyyy"}
                          minDate={new Date("2020-01-01")}
                          maxDate={dayBefore}
                          onChange={(newValue: any) => {
                            setInitDate(newValue);
                            setFiltered(false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "dd/mm/aaaa",
                              }}
                              variant="standard"
                              onKeyDown={onKeyDown}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid style={{ paddingRight: "1rem" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={
                            <span style={{ color: colors.grayText }}>
                              Fecha hasta
                            </span>
                          }
                          value={finishDate}
                          inputFormat={"dd/MM/yyyy"}
                          minDate={dayAfter}
                          maxDate={new Date()}
                          onChange={(newValue: any) => {
                            setFinishDate(newValue);
                            setFiltered(false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "dd/mm/aaaa",
                              }}
                              variant="standard"
                              onKeyDown={onKeyDown}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <TextField
                        style={{ paddingRight: "1em" }}
                        id="card-textfield-terminal"
                        type="text"
                        variant="standard"
                        label="Terminal ID"
                        className="search"
                        value={queryTerminalId}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setQueryTerminalId(e.target.value);
                            setFiltered(false);
                        }}
                    />
                    <TextField
                        style={{ paddingRight: "1em" }}
                        id="card-textfield-code"
                        type="text"
                        variant="standard"
                        label="Código de error"
                        className="search"
                        value={queryCode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setQueryCode(e.target.value);
                            setFiltered(false);
                        }}
                    />
                    <TextField
                        style={{ paddingRight: "1em" }}
                        id="card-textfield-message"
                        type="text"
                        variant="standard"
                        label="Mensaje de error"
                        className="search"
                        value={queryMessage}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setQueryMessage(e.target.value);
                            setFiltered(false);
                        }}
                    />
                    <TextField
                        style={{ paddingRight: "1em" }}
                        id="card-textfield-bin"
                        type="text"
                        variant="standard"
                        label="Primeros 6 dígitos"
                        className="search"
                        value={queryBin}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setQueryBin(e.target.value);
                        setFiltered(false);
                        }}
                    />
                    <Grid style={{ paddingRight: "3rem" }}>
                      {filtered && !validateEmpty ? (
                        <IconButton
                          color="primary"
                          style={{ width: "10em" }}
                          className={styles.buttomFilter}
                          onClick={() => (
                            handleReset(),
                            setFiltered(false),
                            mixpanel.track("apply_filter_errors_history", {
                              Screen: "Diagnostics - Errors History - Clear filters"
                            })
                          )}
                        >
                          Borrar filtros
                          <FilterIcon style={{ paddingLeft: ".5rem" }} />
                        </IconButton>
                      ) : !loading ? (
                        <IconButton
                          color="primary"
                          className={styles.buttomFilter}
                          onClick={() => (
                            setFetchPage(1),
                            fetchErrorsHistory(
                              fetchPage,
                              initDate,
                              finishDate,
                              queryTerminalId,
                              queryCode,
                              queryMessage,
                              queryBin
                            ),
                            setFiltered(true)
                          )}
                        >
                          Aplicar
                          <FilterIcon style={{ paddingLeft: ".5rem" }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="primary"
                          className={styles.buttomFilter}
                        >
                          <div
                            className="rotateIcon"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "2rem",
                              height: "2rem",
                            }}
                          >
                            <LoadingIcon />
                          </div>
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </ErrorBoundary>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} xl={12}>
          {loading ? (
            <Skeleton
              variant="rectangular"
              style={{
                background: colors.background,
                borderRadius: "10px",
              }}
              width={"100%"}
              height={"41.2rem"}
            />
          ) : localErrors != undefined 
          && localErrors.total_errors != undefined 
          && localErrors.total_errors > 0 ? (
            <Card className={styles.cardTable}>
              <Grid className={styles.gridInternal}>
                <ErrorBoundary>
                  <TableCustom
                    state={localErrors.errors}
                    fetchPage={fetchPage}
                    setFetchPage={setFetchPage}
                    total_elements={localErrors?.total_errors}
                    total_pages={localErrors?.total_pages}
                  />
                </ErrorBoundary>
              </Grid>
            </Card>
          ) : (
            <div
              style={{
                width: "100%",
                minHeight: "41.2rem",
              }}
            >
              <AlertMessage
                message={"¡No se encontraron eventos!"}
                customStyle={{ paddingTop: "8rem", paddingBottom: "2rem" }}
              />
            </div>
          )}
        </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
export default ErrorsHistory;
