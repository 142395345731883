import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    headerGrid: {
      paddingTop: "1rem",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "2rem"
    },
    textCardSetting: {
      fontSize: "20px",
      fontWeight: 500,
      color: colors.grayText,
    }
  })
);

export default useStyles;
