import KeyCloakService from "services/KeyCloakService";

const EnableOnRole = (roles) => {
   
    if (KeyCloakService.isLoggedIn) {
         return (KeyCloakService?.hasRole(roles))
        }
};


export default EnableOnRole