import {
  Card,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import { ReactComponent as NotLatLon } from "assets/svg/not_lat_lon.svg";
import useStyles from "./styles";
import colors from "constants/colors";
import terminalService from "services/terminal.service";
import RoleContext from "context/userRole/RoleContext";

interface Column {
  id:
    | "name"
    | "service"
    | "frequency"
    | "collapse"
    | "status"
    | "lastExec"
    | "nextExec";
  label: string;
  align: "left" | "center" | "right";
}

const columns: Column[] = [
  {
    id: "name",
    label: "Proceso/Tarea",
    align: "left",
  },
  {
    id: "service",
    label: "Servicio/App",
    align: "left",
  },
  {
    id: "frequency",
    label: "Frecuencia",
    align: "left",
  },
  {
    id: "lastExec",
    label: "Ult. Ejecución",
    align: "left",
  },
  {
    id: "nextExec",
    label: "Prox. Ejecución",
    align: "left",
  },
  {
    id: "status",
    label: "Estado",
    align: "left",
  },
  {
    id: "collapse",
    label: "",
    align: "right",
  },
];

interface RowProps {
  row: Task;
}

interface Task {
  name: string;
  service: string;
  frequency: string;
  status: string;
  status_message: string;
  actions: string[];
  lastExec: string;
  nextExec: string;
  detail: any;
}

const RowContent: React.FC<RowProps> = ({ row }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const chipStyles = (status: string) => ({
    backgroundColor:
      status === "EXECUTED"
        ? colors.executed
        : status === "FAILED"
        ? colors.failed
        : colors.error,
  });

  return (
    <>
      <TableRow key={uuidv4()} onClick={() => setIsOpen(!isOpen)} hover>
        {columns.map((column) =>
          column.id === "collapse" ? (
            <TableCell key={uuidv4()} align="right">
              {row.detail && (isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
            </TableCell>
          ) : (
            <TableCell key={uuidv4()}>
              <Typography
                style={{
                  color: colors.textTable,
                  fontSize: ".875rem",
                  lineHeight: "1.25rem",
                }}
              >
                {column.id === "status" ? (
                  <Chip
                    label={row[column.id]}
                    icon={
                      row[column.id] === "EXECUTED" ? (
                        <CheckCircleIcon />
                      ) : row[column.id] === "FAILED" ? (
                        <CancelIcon />
                      ) : (
                        <ErrorIcon />
                      )
                    }
                    style={chipStyles(row[column.id])}
                  />
                ) : (
                  row[column.id]
                )}
              </Typography>
            </TableCell>
          )
        )}
      </TableRow>
      {row.detail && <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Chip
              label={row["status_message"]}
              style={{
                backgroundColor:
                  row["status"] === "EXECUTED"
                    ? colors.executed
                    : row["status"] === "FAILED"
                    ? colors.failed
                    : colors.error,
              }}
            />
            
          </Collapse>
        </TableCell>
      </TableRow>}
    </>
  );
};

const ScheduledTasks = () => {
  const styles = useStyles();
  const [rows, setRows] = useState<Task[]>([])
  
  const fetchScheduledTasksGet = async () => {
    try {
      const resp = await terminalService.scheduled_tasks();
      if (resp.data) {
        setRows(resp.data);
      }
    } catch (error) {
      console.error("Error fetching scheduled tasks:", error);
    }
  };

  useEffect(() => {
    fetchScheduledTasksGet()
    
  }, [])
  

  return (
    <>
      <Paper
        sx={{
          //maxWidth: '875px',
          width: "100%",
          background: colors.background,
          boxShadow: "none",
          overflowY: "hidden",
          overflowX: "hidden",
          padding: "1rem",
          boxSizing: "border-box",
          paddingBottom: "12rem",
          borderRadius: "8px",
          verticalAlign: "bottom",
        }}
      >
        <Grid container spacing={2}>
          <div
            style={{
              background: "rgba(255, 255, 255, 0.8)",
              top: 0,

              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "1rem",
              flexDirection: "column",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid className={styles.headerGrid}>
                  <Typography className={styles.textCardSetting}>
                    Tareas Programadas
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {rows && rows.length > 0 ? <Grid
              container
              sx={{
                backgroundColor: colors.background,
                borderRadius: "8px",
                padding: "1rem",
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: "100%",
                  marginRight: "10px",
                }}
              >
                <Table aria-label="collapsible table" sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      {columns.map((col) => (
                        <TableCell key={col.id} align={col.align}>
                          {col.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <RowContent key={uuidv4()} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid> : 
            (
              <NotLatLon />
            )}
          </div>
        </Grid>
      </Paper>
    </>
  );
};

export default ScheduledTasks;
